<template>
    <div class="row">
        <div class="col-12">
                    <div class="row">
                        <div class="col-sm-4">
                            <a href="#" data-toggle="lightbox" data-title="sample 1 - white" data-gallery="gallery">
                            <img src="https://picsum.photos/300/300" class="img-fluid mb-2" alt="white sample" />
                            </a>
                            <input class="form-check-input" type="checkbox">
                        </div>
                        <div class="col-sm-4">
                            <a href="#" data-toggle="lightbox" data-title="sample 2 - black" data-gallery="gallery">
                            <img src="https://picsum.photos/300/300" class="img-fluid mb-2" alt="black sample" />
                            </a>
                            <input class="form-check-input" type="checkbox">
                        </div>
                        <div class="col-sm-4">
                            <a href="#" data-toggle="lightbox" data-title="sample 3 - red" data-gallery="gallery">
                            <img src="https://picsum.photos/300/300" class="img-fluid mb-2" alt="red sample" />
                            </a>
                            <input class="form-check-input" type="checkbox">
                        </div>
                        <div class="col-sm-4">
                            <a href="#" data-toggle="lightbox" data-title="sample 4 - red" data-gallery="gallery">
                            <img src="https://picsum.photos/300/300" class="img-fluid mb-2" alt="red sample" />
                            </a>
                            <input class="form-check-input" type="checkbox">
                        </div>
                        <div class="col-sm-4">
                            <a href="#" data-toggle="lightbox" data-title="sample 5 - black" data-gallery="gallery">
                            <img src="https://picsum.photos/300/300" class="img-fluid mb-2" alt="black sample" />
                            </a>
                            <input class="form-check-input" type="checkbox">
                        </div>
                        <div class="col-sm-4">
                            <a href="#" data-toggle="lightbox" data-title="sample 6 - white" data-gallery="gallery">
                            <img src="https://picsum.photos/300/300" class="img-fluid mb-2" alt="white sample" />
                            </a>
                            <input class="form-check-input" type="checkbox">
                        </div>
                        <div class="col-sm-4">
                            <a href="#" data-toggle="lightbox" data-title="sample 7 - white" data-gallery="gallery">
                            <img src="https://picsum.photos/300/300" class="img-fluid mb-2" alt="white sample" />
                            </a>
                            <input class="form-check-input" type="checkbox">
                        </div>
                    </div>
        </div>
    </div>
</template>
