<template>
    <DataTable :value="datos" :row-style-class="getRowStyle">
    <Column field="codigo" :header="$t('general.codigo')"></Column>
    <Column field="concepto" :header="$t('general.concepto')"></Column>
    <Column field="descuento" :header="$t('general.descuento')"></Column>
    <Column field="cantidad" :header="$t('general.cantidad')"></Column>
    <Column field="precio" :header="$t('general.precio')"></Column>
    <Column field="importe" :header="$t('general.importe')"></Column>
    </DataTable>
</template>
<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
export default {
    props: ['id', 'servicio', 'tipodefactura', 'ffac','destinatario','asegurado'],
    components: {
        DataTable,
        Column 
    },
    data() {
        return {
            datos: [ ],
            siguientenumero:'',
        }
    },
    methods: {
        getRow(rowData) {
            // Retorna el elemento de fila que se debe arrastrar
            // Puedes ajustar esto según la estructura de tus datos y fila
            return rowData;
        },
        
       async cargardatos() {
            //POST  modulos/pwgsapi/index.php/facturas/:id_servicio/previo-facturacion
           var codigo = '';
           var concepto = '';
           var precio = '';
           var cantidad = 1;
           var importe = '';
           var descuento = 0;
           var i = 0;

               i = 0;
               this.datos = [];
           for (var trabajo in this.$props.destinatario[1].trabajos)  {
               codigo = this.$props.destinatario[1].trabajos[trabajo].codigo;
               concepto = this.$props.destinatario[1].trabajos[trabajo].nombre;
               precio = this.$props.destinatario[1].trabajos[trabajo].total;
               cantidad = 1;
               importe = this.$props.destinatario[1].trabajos[trabajo].total;
               descuento = 0;
               this.datos[i] = { codigo, concepto, precio, cantidad, importe, descuento };
               
            i++;             
           }   
         }
    },
    mounted() {
        this.cargardatos();
    },
    watch: {
        id() {
            this.cargardatos();
        },
        ffac() { 
            this.cargardatos();
        },
         


    }
}
</script>